import React, { useContext } from 'react'
import { Grid, Grow, Modal, Container, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import UserTable from 'components/UserTable'
import Context from 'reactContext'
import Main from 'components/main'
import Signup from 'components/signup'

const UserList = () => {
  const { viewSetIn, view } = useContext(Context)
  const useStyles = makeStyles((theme) => ({
    paper: {
      outline: 0,
      padding: 20,
      width: 380,
      maxWidth: '100%',
      color: theme.palette.text.primary,
      background: theme.palette.background.paper,
    },
  }))
  const classes = useStyles()

  return (
    <Main>
      <Grid container>
        <Grid item xs className="mb-6">
          <Button className="mr-6" variant="contained" color="primary" onClick={() => viewSetIn(['createDialogOpen'], true)}>
            创建子用户
          </Button>
        </Grid>
      </Grid>
      <UserTable />
      <Modal open={view.get('createDialogOpen')} onClose={() => viewSetIn(['createDialogOpen'], false)} className="flex items-center justify-center">
        <Grow in={view.get('createDialogOpen')}>
          <Container maxWidth="xs" className={classes.paper}>
            <Signup />
          </Container>
        </Grow>
      </Modal>
    </Main>
  )
}

export default UserList
