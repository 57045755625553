import React, { useContext, useEffect, useState } from 'react'
import Context from 'reactContext'
import I from 'immutable'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  TableHead,
  TableRow,
  Input,
  Button,
  ButtonGroup,
  TableBody,
  Table,
  TableContainer,
  FormControlLabel,
  Switch,
  CircularProgress,
  TableCell,
} from '@material-ui/core'
import SnackMessage from 'components/snackbar'
import { formattedTime } from 'utils'
import { httpDelete, put, get, post } from 'utils/request'

const UserTable = () => {
  const { users, view, usersSet, selfSet, self } = useContext(Context)
  const [loading, setLoading] = useState(true)
  const allRemarks = self.getIn(['settings', 'enterprise_user_remarks'], {})
  const [deleteUserId, setDeleteUserId] = useState('')
  const [remarksUserId, setRemarksUserId] = useState('')
  const [remarks, setRemarks] = useState('')
  const [userId, setUserId] = useState('')
  const [dialogVisible, setDialogVisible] = useState(false)
  const [diaVisible, setDiaVisible] = useState(false)
  const [password, setPassword] = useState(false)
  const [open, setOpen] = useState(false)
  const [resMessage, setResMessage] = useState('')
  const [userRemarksDialogOpen, setUserRemarksDialogOpen] = useState(false)
  const changePassword = async (params) => {
    const res = await put('managers/users/change_password', params)
    if (res.ok) {
      setDialogVisible(false)
      setOpen(true)
      setResMessage('密码重置成功')
    } else {
      setOpen(true)
      setResMessage('密码重置失败')
    }
  }

  const updateUserRemarks = async (params) => {
    const res = await post('managers/users/update_remarks', params)
    if (res.ok) {
      selfSet(res.self)
    }
  }

  const usersIndex = async () => {
    const res = await get('managers/users')
    if (res.ok) {
      usersSet(I.fromJS(res.users))
      setLoading(false)
    }
  }

  const removeUsers = async (u) => {
    const res = await httpDelete('managers/users/remove', { users: u })
    if (res.ok) {
      users.forEach((i) => usersSet(users.delete(i)))
    }
    setDiaVisible(false)
    setOpen(true)
    setResMessage('操作成功')
  }

  const toggleUsers = async (u, isToggledUp) => {
    const res = await post('managers/users/toggle_up', { users: u, up: isToggledUp })
    if (res.ok) {
      setOpen(true)
      setResMessage('操作成功')
    } else {
      setOpen(true)
      setResMessage('已达上限，无法操作')
    }
    return res
  }

  const userRemarksDialog = () => {
    return (
      <Dialog open={userRemarksDialogOpen} onClose={() => setUserRemarksDialogOpen(false)}>
        <DialogContent>
          <div className="text-center">
            <Input autoFocus placeholder="设置备注" value={remarks} onChange={(e) => setRemarks(e.target.value)} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUserRemarksDialogOpen(false)} color="primary">
            取消
          </Button>
          <Button
            onClick={() => {
              updateUserRemarks({ user_id: remarksUserId, remarks })
              setUserRemarksDialogOpen(false)
            }}
            color="primary"
          >
            确定
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const rowData = Object.values(users.toJS())

  useEffect(() => {
    usersIndex()
  }, [open])

  return (
    <div className="text-center">
      {userRemarksDialog()}
      {loading ? (
        <CircularProgress size={30} />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>在线</TableCell>
                <TableCell>备注</TableCell>
                <TableCell>邮箱</TableCell>
                <TableCell>手机</TableCell>
                <TableCell>最近登录</TableCell>
                <TableCell>操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData
                .filter((v) => (v.email || v.mobile).indexOf(view.get('searchText')) !== -1)
                .map((v) => (
                  <TableRow key={v.created_at}>
                    <TableCell align="left">
                      <FormControlLabel
                        control={<Switch checked={v.settings.enterprise_up} onChange={() => toggleUsers([v.id], !v.settings.enterprise_up)} />}
                        label={`剩余${3 - (v.settings.enterprise_offline_records || []).length}次`}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <div
                        role="presentation"
                        className="cursor-pointer"
                        onClick={() => {
                          setRemarksUserId(v.id)
                          setRemarks(v.text)
                          setUserRemarksDialogOpen(true)
                        }}
                      >
                        {allRemarks[v.id]} <i className="iconfont icon-edit ml-3" />
                      </div>
                    </TableCell>
                    <TableCell align="left">{v.email}</TableCell>
                    <TableCell align="left">{v.mobile}</TableCell>
                    <TableCell align="left">{v.last_online_moment ? formattedTime(v.last_online_moment) : '无记录'}</TableCell>
                    <TableCell align="left">
                      <ButtonGroup color="primary" variant="outlined" size="small">
                        <Button
                          onClick={() => {
                            setUserId(v.id)
                            setDialogVisible(true)
                          }}
                        >
                          重置密码
                        </Button>
                        <Button
                          onClick={() => {
                            setDeleteUserId(v.id)
                            setDiaVisible(true)
                          }}
                        >
                          删除用户
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Dialog open={dialogVisible} onClose={() => setDialogVisible(false)}>
        <DialogContent>
          <Input value={password} type="password" placeholder="新密码" fullWidth onChange={(e) => setPassword(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDialogVisible(false)}>
            取消
          </Button>
          <Button color="primary" onClick={() => changePassword({ id: userId, new_password: password })}>
            提交
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={diaVisible} onClose={() => setDiaVisible(false)}>
        <DialogContent>您确定要删除该用户吗？</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDiaVisible(false)}>
            取消
          </Button>
          <Button color="primary" onClick={() => removeUsers([deleteUserId])}>
            确定
          </Button>
        </DialogActions>
      </Dialog>
      <SnackMessage open={open} onClose={() => setOpen(false)} message={resMessage} status="info" />
    </div>
  )
}

export default UserTable
