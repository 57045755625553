import React, { useContext, useState } from 'react'
import { Button, Input, Container, CircularProgress } from '@material-ui/core'
import Context from 'reactContext'
import SnackMessage from 'components/snackbar'
import { post } from 'utils/request'

const Signup = () => {
  const { viewSetIn, usersMerge } = useContext(Context)
  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [open, setOpen] = useState(false)
  const [resMessage, setResMessage] = useState('')
  const signup = async (params) => {
    const res = await post('managers/users', params)
    if (res.ok) {
      usersMerge(res.user)
      setOpen(true)
      setResMessage('子用户已创建')
      viewSetIn(['createDialogOpen'], false)
    } else {
      setOpen(true)
      setResMessage(res.message)
    }
    setLoading(false)
  }

  return (
    <Container maxWidth="xs">
      <Input autoFocus className="mt-6" fullWidth placeholder="手机或邮箱" value={username} onChange={(e) => setUsername(e.target.value)} />
      <Input className="mt-6" placeholder="密码" fullWidth type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      <Input className="mt-6" placeholder="确认密码" type="password" fullWidth value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} />
      <Button
        color="primary"
        variant="contained"
        fullWidth
        disabled={loading}
        className="mt-8 mb-4"
        onClick={() => {
          setLoading(true)
          signup({ email_or_mobile: username.trim(), password, password_confirmation: passwordConfirmation })
        }}
      >
        {loading ? <CircularProgress size={24} /> : '立即创建'}
      </Button>
      <SnackMessage open={open} onClose={() => setOpen(false)} message={resMessage} status="info" />
    </Container>
  )
}

export default Signup
